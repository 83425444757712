body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  color: #ffffff;
  background-color:            #1b1b1b;
  --headerbg:                  #2f2136;
  --primary:                   #181818;
  --secondary:                 #8957b8;
  --cardbg:                    #2b2b2b;
  --footer-icons:              #555555;
  --button:                    #8957b8;
  --input:                     #2b2b2b;
  --project-name:              #cccccc;
  --project-description:       #ececec;

}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
